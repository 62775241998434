import React from "react";

import { MenuItem, Select, Stack, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { KeyboardArrowDownRounded } from "@mui/icons-material";

import TablePagination from "./TablePagination";
import { ROWS_IN_TABLE } from "constants/globals";
import { useTranslation } from "react-i18next";

export const selectWrapper: SxProps<Theme> = {
  "& .MuiSelect-outlined": {
    padding: "4px 0 4px 12px",
    fontWeight: 600,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
  "& svg": {
    fill: theme => theme.palette.grey[500],
  },
};
export const showSelect: SxProps<Theme> = {
  color: theme => theme.palette.grey[500],
};

interface IProps {
  total: number;
  limit: number;
  page: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const LimitAndPagination: React.FC<IProps> = ({ total, limit, page, setLimit, setPage }) => {
  const { t } = useTranslation("common");
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: "20px" }}>
      <TablePagination
        count={Math.ceil((total ?? 0) / (limit ?? 1))}
        page={page}
        onChange={(e, value) => setPage(value)}
      />

      <Stack direction="row" alignItems="center">
        <Typography sx={showSelect}>{t("perPage")}</Typography>
        <Select
          value={limit.toString()}
          sx={selectWrapper}
          IconComponent={KeyboardArrowDownRounded}
          onChange={e => {
            setPage(1);
            setLimit(+e.target.value);
          }}
        >
          {ROWS_IN_TABLE.map(v => (
            <MenuItem key={v} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Stack>
  );
};

export default LimitAndPagination;
