import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";

import { useSnackbar } from "notistack";

import { useGetAxiosRequests } from "config/axiosConfig";
import { removeSession, setSession } from "helpers/sessionToken";
import { AppRoutes, TokenExchangeData } from "types";

export const useExchangeMutation = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { exchange } = useGetAxiosRequests();

  return useMutation<TokenExchangeData, AxiosError<any>, string>(exchange, {
    onSuccess: async ({ id_token, refresh_token, access_token }) => {
      setSession({
        id_token,
        access_token,
        refresh_token,
        timestamp: new Date().getTime(),
      });
      await navigate(AppRoutes.USER);
    },
    onError: error => {
      enqueueSnackbar("Token request Error!", { variant: "error" });
      navigate(AppRoutes.LOGIN);
    },
  });
};

export const useLogOutMutation = () => {
  const navigate = useNavigate();
  const { logOut } = useGetAxiosRequests();

  return useMutation(logOut, {
    onSuccess: async () => {
      removeSession();
      await navigate(AppRoutes.LOGIN, { replace: true });
    },
    onError: error => {
      console.error("* logout error", error);
    },
  });
};
