import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { getUser, isExpiredToken, removeSession } from "helpers/sessionToken";
import { AppRoutes } from "types";

const ProtectedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  let location = useLocation();

  if (isExpiredToken()) {
    removeSession();
  }

  const user = getUser();
  if (!user) {
    return <Navigate to={AppRoutes.LOGIN} state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
