import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { Theme, alpha } from "@mui/material";

export const flexContainer: SxProps<Theme> = {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: { xs: "column", sm: "row" },
};

export const copyrightWrapper: SxProps<Theme> = {
  py: "15px",
};

export const flexContainerWrapper: SxProps<Theme> = {
  borderTop: theme => `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
  bgcolor: "background.secondary",
  py: { xs: "40px", sm: "48px", md: "64px" },
};
export const logoWrapper: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  mb: { xs: "10px", sm: "24px" },
};
export const linksWrapper: SxProps<Theme> = { maxWidth: 740, width: "100%" };

export const langSelect: SxProps<Theme> = {
  backgroundColor: "common.white",
  "& .MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
};
