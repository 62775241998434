import React from "react";
import { useTranslation } from "react-i18next";

import { AccountCircleOutlined, Logout } from "@mui/icons-material";
import { Avatar, IconButton, ListItemIcon, MenuItem } from "@mui/material";

import MenuWithArrow from "./MenuWithArrow";
import useMenuState from "hooks/useMenuState";
import { useLogOutMutation } from "hooks/reactQueryHooks/authHooks";
import { getSession } from "helpers/sessionToken";

interface UserAvatarWithMenuProps {
  avatarIconColor?: string;
}

const UserAvatarWithMenu: React.FC<UserAvatarWithMenuProps> = ({ avatarIconColor = "action.active" }) => {
  const { t } = useTranslation("common");
  const [anchorEl, handleClick, handleClose] = useMenuState();

  const { mutate: logOut } = useLogOutMutation();

  const handleLogOut = () => {
    const session = getSession();
    if (session) {
      logOut({ refresh_token: session.refresh_token, id_token: session.id_token });
    }
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <Avatar sx={{ width: 40, height: 40, bgcolor: "transparent" }}>
          <AccountCircleOutlined sx={{ fontSize: 40, color: avatarIconColor }} />
        </Avatar>
      </IconButton>
      <MenuWithArrow anchorEl={anchorEl} onClose={handleClose} onClick={handleClose}>
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {t("buttons.logout")}
        </MenuItem>
      </MenuWithArrow>
    </>
  );
};

export default UserAvatarWithMenu;
