import React from "react";
import { Box, CircularProgress } from "@mui/material";

interface PageLoadingSpinnerProps {
  blockHeight?: React.CSSProperties["height"];
  blockWidth?: React.CSSProperties["width"];
}

const PageLoadingSpinner: React.FC<PageLoadingSpinnerProps> = ({ blockHeight = "100vh", blockWidth = "100vw" }) => {
  return (
    <Box
      sx={{ height: blockHeight, width: blockWidth, display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CircularProgress size={60} />
    </Box>
  );
};

export default PageLoadingSpinner;
