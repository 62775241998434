import { LocaleTypes } from "types";

export const HEADER_HEIGHT = 72;
export const FOOTER_HEIGHT = 56;

export const ROWS_IN_TABLE = [5, 10, 25, 50, 100];

export const locales: LocaleTypes[] = ["en", "pt", "ar"];
export const LOCALES_MAP = {
  en: { flag: "GB", text: "English", muiLocale: "enUS" },
  pt: { flag: "PT", text: "Portuguese", muiLocale: "ptPT" },
  ar: { flag: "SA", text: "Arabic", muiLocale: "arEG" },
};

export const publicFolderPath = process.env.PUBLIC_URL;

export const PAYMENT_TYPES = [
  {
  label: "Domestic",
  value:"domestic-payment-consents"
  },
  {
  label: "Domestic Scheduled",
  value:"domestic-scheduled-payment-consents"
  },
  {
   label: "Domestic SO",
   value:"domestic-standing-order-consents"
  },
  {
   label: "Domestic Vrp",
   value:"domestic-vrp-consents"
   },
   {
     label: "International",
     value:"international-payment-consents"
   },
   {
     label: "International Scheduled",
     value:"international-scheduled"
   },
   {
     label: "International SO",
     value:"international-standing-order-consents"
   }
];

export const timeFormat = "dd MMM yyyy HH:mm";

export const isConsumerBank = (tenantName: string) => tenantName === "consumerbank-jpmorgan";
export const isAUB = (tenantName: string) => tenantName === "aub";
