import { Chip, ChipProps, lighten, styled } from "@mui/material";

import colors from "constants/colors";

const ActivityLabel = styled(Chip)<ChipProps>(({ theme }) => ({
  borderRadius: "2px",
  fontWeight: 700,
  height: 26,
  "&.MuiChip-colorSuccess": {
    backgroundColor: colors.chipSuccessBg,
    color: colors.chipSuccess,
  },
  "&.MuiChip-colorError": {
    backgroundColor: colors.chipErrorBg,
    color: colors.chipError,
  },
  "&.MuiChip-colorInfo": {
    backgroundColor: lighten(theme.palette.info.main, 0.8),
    color: theme.palette.info.main,
  },
}));

export default ActivityLabel;
