import React from "react";

import { Pagination, Paper, Theme } from "@mui/material";
import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";

const getStyles = (noCircle?: boolean): SxProps<Theme> => ({
  "& button": {
    color: theme => theme.palette.grey[400],
    fontWeight: 600,
  },
  "& button.Mui-selected": {
    color: theme => (noCircle ? theme.palette.primary.main : "common.white"),
    backgroundColor: noCircle ? "common.white" : "",
  },
});

interface TablePaginationProps {
  count?: number;
  page?: number;
  onChange?: (event: React.ChangeEvent<unknown>, page: number) => void;
  noCircle?: boolean;
}

const TablePagination: React.FC<TablePaginationProps> = ({ count, page, onChange, noCircle }) => {
  return (
    <Paper elevation={0} sx={{ p: "8px" }}>
      <Pagination color="primary" size="small" sx={getStyles(noCircle)} count={count} page={page} onChange={onChange} />
    </Paper>
  );
};

export default TablePagination;
