import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-chained-backend";
// import LocalStorageBackend from "i18next-localstorage-backend";
import HttpApi from "i18next-http-backend";

import { locales } from "constants/globals"; // fallback http load

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      backends: [/*LocalStorageBackend,*/ HttpApi],
      backendOptions: [
        // {
        //   /* options for primary backend */
        // },
        {
          /* options for secondary backend */
          loadPath: `/locales/{{lng}}/{{ns}}.json`, // http load path for my own fallback
        },
      ],
    },
    lng: locales[0],
    fallbackLng: false,
    supportedLngs: locales,
    debug: process.env.NODE_ENV !== "production",

    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
