import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Container } from "@mui/material";

import { UserAvatarWithMenu, LocaleSwitcher } from "components/common";
import { getUser } from "helpers/sessionToken";
import { getEnvVarsStorage } from "helpers";
import { isConsumerBank } from "constants/globals";
import { appBar, toolBar, appLogo, link, consumerAppLogo } from "./styles";
import { AppRoutes } from "types";

const Header: React.FC = () => {
  const user = getUser();
  const envs = getEnvVarsStorage();

  const isConsumer = isConsumerBank(envs?.tenantName);

  return (
    <AppBar position="static" sx={appBar}>
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={toolBar}>
          <Link to={AppRoutes.HOME} style={link}>
            <img src={envs?.logo ?? ""} alt="logo" style={isConsumer ? consumerAppLogo : appLogo} />
          </Link>
          <div>
            <LocaleSwitcher />
            {user && <UserAvatarWithMenu />}
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
