import { SxProps } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { Theme } from "@mui/material";

export const appBar: SxProps<Theme> = {
  background: theme => theme.palette.common.white,
  borderBottom: theme => `1px solid ${theme.palette.background.secondary}`,
  boxShadow: "none",
  zIndex: theme => theme.zIndex.drawer + 1,
};

export const toolBar: SxProps<Theme> = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
  py: "8px",
};
export const nav: SxProps<Theme> = {
  flexGrow: 1,
  display: "flex",
  mx: { xs: "10px", sm: "20px", md: "80px" },
};

export const appLogo = {
  height: "48px",
  cursor: "pointer",
};
export const consumerAppLogo = {
  height: "24.5px",
  cursor: "pointer",
};

export const link = {
  display: "flex",
  alignItems: "center",
};

export const signInBtn: SxProps<Theme> = {
  color: "text.primary",
  borderColor: "text.primary",
  width: "fit-content",
  mx: "7px",
  "&:hover": {
    borderColor: "text.primary",
  },
  "& .MuiButton-endIcon>*:nth-of-type(1)": {
    fontSize: "12px",
    ml: "8px",
  },
};
export const navBtn: SxProps<Theme> = {
  color: "text.primary",
  ml: "10px",
};
